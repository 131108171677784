import { createTheme } from '@mui/material/styles';
import '../../fonts/fonts.css';
import { Colors } from './colors';

export const walterreTheme = createTheme({
  palette: {
    primary: {
      light: '#FE8403',
      main: '#FE8403',
      dark: '#fe8503d9',
      contrastText: '#000',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
    info: {
      light: '#ff7961',
      main: '#575757',
      dark: '#e3e1e1',
      contrastText: '#ff7961',
    },
    warning: {
      light: '#425df5',
      main: '#425df5',
      dark: '##425df5d9',
      contrastText: '#000',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '1.2rem',
          lineHeight: '1.3',
          margin: '0',
          padding: '0',
          outline: '0',
          boxSizing: 'border-box',
          fontFamily: "'Roboto', sans-serif", /* Roboto is our local font from the "fonts" folder */
          fontWeight: 400,
          color: Colors.black,
        },
      },
    },
  },
});
