import {
  UseQueryResult,
} from '@tanstack/react-query';
import { useQuery } from '../../hooks/useQuery';
import { OptionsQuery } from '../../libs/interfaces';
import { AddressesGouvProps } from './addresses.domain';
import { AdressesGouvRepository } from './adresses.repository';

export const ADRESSES_GOUV_LIST = 'adressesGouvList';

export const useAdressesGouvList = ({
  search,
}: {search: string}, options?: OptionsQuery): UseQueryResult<AddressesGouvProps, unknown> => useQuery(
  [ADRESSES_GOUV_LIST, search],
  () => new AdressesGouvRepository().addressesList({
    search,
  }),
  {
    ...options,
  },
);
