import {
  Button, ButtonProps, CircularProgress, Tooltip,
} from '@mui/material';
import React from 'react';

export interface GenericButtonProps extends ButtonProps {
  tooltip?: string;
  tooltipPlacement?: 'bottom' | 'left' | 'right' | 'top' | 'bottom-end' | 'bottom-start' | 'left-end' | 'left-start' | 'right-end' | 'right-start' | 'top-end' | 'top-start';
  label?: string;
  isLoading?: boolean;
  icon?: React.ReactNode;
}

function GenericButton({
  tooltip, tooltipPlacement, label, style, icon, isLoading, ...props
}: GenericButtonProps) {
  return (
    <Tooltip title={tooltip} placement={tooltipPlacement}>
      <Button {...props} sx={style}>
        {label}
        {isLoading ? (
          <CircularProgress />
        ) : icon }
      </Button>
    </Tooltip>
  );
}

GenericButton.defaultProps = {
  tooltip: '',
  tooltipPlacement: 'right',
  label: '',
  isLoading: false,
  icon: null,
};

export default GenericButton;
