import { TableCell } from '@mui/material';

interface Props {
  value?: string | number | boolean;
  minWidth: number;
}

export function ListItem({ value, minWidth }: Props) {
  return (
    <TableCell
      style={{
        minWidth,
        padding: '5px 16px',
      }}
    >
      {value}
    </TableCell>
  );
}

ListItem.defaultProps = {
  value: '',
};
