import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion, AccordionDetails, AccordionSummary, Typography,
} from '@mui/material';
import { get } from 'lodash';
import { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  EBuildingType, EPropertyType, ListBuildingType, ListHabitantType, ListPropertyType,
} from '../../../../modules/project/domain/project.entity';
import { InputNumber } from '../../Form/InputNumber';
import { InputText } from '../../Form/InputText';
import SelectInput from '../../Form/SelectInput';
import IconAddButtonWithHoverEffect from '../../shared/Buttons/IconAddButtonWithHoverEffect';
import IconRemoveButtonWithHoverEffect from '../../shared/Buttons/IconRemoveButtonWithHoverEffect';
import { buildingDefaultValue } from './buildingDefaultValue';

export function BuildingArray() {
  const [expanded, setExpanded] = useState(-1);
  const { control, watch, formState: { errors } } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name: 'buildings' });
  const numberBuildings = watch('buildings').length || 0;
  return (
    <div>
      {fields.map((_, index) => {
        const watchPropertyType = watch(`buildings.${index}.propertyType`);
        const watchBuildingType = watch(`buildings.${index}.buildingType`);
        return (
          <Accordion
            expanded={expanded === index || Boolean(get(errors, `buildings.${index}`, false))}
            onChange={() => setExpanded((expanded === index) ? -1 : index)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                position: 'relative',
                borderColor: get(errors, 'buildings', false) ? 'red' : 'transparent',
              }}
            >
              <Typography>
                Batiment
                {' '}
                {index + 1}
              </Typography>
              {numberBuildings > 1 ? (
                <IconRemoveButtonWithHoverEffect
                  tooltip="Supprimer"
                  onClick={() => remove(index)}
                />
              ) : null}
            </AccordionSummary>
            <AccordionDetails>
              {numberBuildings > 1 ? (
                <InputText
                  name={`buildings.${index}.name`}
                  label="Nom du bâtiment"
                />
              ) : null}
              <InputNumber
                name={`buildings.${index}.shab`}
                label="Surface chauffée"
                isRatio
                unity="m²"
              />
              <InputNumber
                name={`buildings.${index}.constructionYear`}
                label="Année de construction"
                isThousandSeparator={false}
              />
              <SelectInput
                name={`buildings.${index}.propertyType`}
                label="Usage"
                choices={ListPropertyType}
              />
              {
                watchPropertyType === EPropertyType.RESIDENTIAL ? (
                  <>
                    <SelectInput
                      name={`buildings.${index}.habitantType`}
                      label="Type d’habitants"
                      choices={ListHabitantType}
                    />
                    <InputNumber
                      name={`buildings.${index}.numberLot`}
                      label="Nombre de lots"
                    />
                  </>
                ) : (
                  <SelectInput
                    name={`buildings.${index}.buildingType`}
                    label="Type de bâtiment"
                    choices={ListBuildingType}
                  />
                )
              }
              {
                watchPropertyType === EPropertyType.TERTIARY
                && watchBuildingType === EBuildingType.EHPAD ? (
                  <InputNumber
                    name={`buildings.${index}.numberBed`}
                    label="Nombre de lits"
                  />
                  ) : null
              }
              {
                watchPropertyType === EPropertyType.TERTIARY
                && watchBuildingType === EBuildingType.HOTEL ? (
                  <InputNumber
                    name={`buildings.${index}.numberRoom`}
                    label="Nombre de chambres"
                  />
                  ) : null
              }
            </AccordionDetails>
          </Accordion>
        );
      })}
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '15px 10px',
      }}
      >
        <Typography style={{ color: 'gray' }}>
          Ajouter un batiment
        </Typography>
        <IconAddButtonWithHoverEffect
          tooltip="Ajouter"
          onClick={() => append(buildingDefaultValue)}
        />
      </div>

    </div>
  );
}
