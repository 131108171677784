import {
  useMutation,
  UseMutationResult,
  UseQueryResult,
} from '@tanstack/react-query';
import { useQuery } from '../../../hooks/useQuery';
import { IdResponse, PaginateResponse } from '../../../libs/ddd/interfaces/base.interface';
import { OptionsQuery } from '../../../libs/interfaces';
import { UserRepository } from '../api/user.repository';
import {
  ChangePasswordProps,
  CreateUserProps,
  ETeam, ForgotPasswordProps, LoginProps, LoginResponseProps, PaginateUserProps, UpdateUserMeProps, UpdateUserProps,
} from '../domain/user.entity';
import { UserResponse } from '../responses/user.response';

export const USERS_LIST = 'usersList';
export const USER = 'user';
export const USER_ME = 'userMe';

export const useLogin = (
  onSuccess,
  onError,
): UseMutationResult<LoginResponseProps, unknown, LoginProps> => useMutation((values: LoginProps) => new UserRepository().login(values), {
  onSuccess,
  onError,
});

export const useUsersList = ({
  team,
}: {
  team?: ETeam;
}): UseQueryResult<UserResponse[], unknown> => useQuery(
  [USERS_LIST, team ?? ''],
  () => new UserRepository().allUsers(team),
);

export const useUserFindMe = (): UseQueryResult<UserResponse, unknown> => useQuery(
  [USER_ME],
  () => new UserRepository().findMe(),
);

export const useChangePassword = (
  onSuccess,
  onError,
): UseMutationResult<IdResponse, unknown, ChangePasswordProps> => useMutation((values: ChangePasswordProps) => new UserRepository().changePassword(values), {
  onSuccess,
  onError,
});

export const useForgotPassword = (
  onSuccess,
  onError,
): UseMutationResult<IdResponse, unknown, ForgotPasswordProps> => useMutation((values: ForgotPasswordProps) => new UserRepository().forgotPassword(values), {
  onSuccess,
  onError,
});

export const useUsersListPaginate = ({
  page,
  limit,
  search,
  sort,
  order,
}: PaginateUserProps, options: OptionsQuery): UseQueryResult<PaginateResponse<UserResponse[]>, unknown> => useQuery(
  [USERS_LIST, page, limit, search, sort, order],
  () => new UserRepository().paginateUsers({
    page: page + 1, limit, search, sort, order,
  }),
  options,
);

export const useUserRemove = (
  onSuccess,
): UseMutationResult<IdResponse, unknown, number> => useMutation((id: number) => new UserRepository().removeUser(id), {
  onSuccess,
});

export const useUserUpdate = (
  onSuccess,
): UseMutationResult<IdResponse, unknown, UpdateUserProps> => useMutation((user: UpdateUserProps) => new UserRepository().updateUser(user), {
  onSuccess,
});

export const useUserMeUpdate = (
  onSuccess,
): UseMutationResult<IdResponse, unknown, UpdateUserMeProps> => useMutation((user: UpdateUserMeProps) => new UserRepository().updateMe(user), {
  onSuccess,
});

export const useUserCreate = (
  onSuccess,
): UseMutationResult<IdResponse, unknown, CreateUserProps> => useMutation((user: CreateUserProps) => new UserRepository().createUser(user), {
  onSuccess,
});

export const useUserOne = ({ id }): UseQueryResult<UserResponse, IdResponse> => useQuery(
  [USER, id],
  () => new UserRepository().oneUser(id),
);

export const useUserProfilPicture = (
  onSuccess,
): UseMutationResult<IdResponse, unknown, { file: Blob;title?: string }> => useMutation((values) => new UserRepository().updateProfilPictureUser(values), { onSuccess });
