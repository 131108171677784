import styled from 'styled-components';

export const Wrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;

@media (max-width: 900px) {
  flex-direction: column;
}
`;

export const WrapperCenter = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
`;
export const DivContainer = styled.div`
width: 45%;
@media (max-width: 900px) {
  width: 100%;
}
`;

export const DivContainerLarger = styled.div`
width: 70%;
`;
