/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

export enum ESearchSelect {
  PROJECT = 'project',
  CONTACT = 'contact',
  COMPANY = 'company',
}

export interface SearchInitialState<T> {
  select: ESearchSelect,
  list: T[],
  show: boolean,
  count: number,
  isLoading: boolean,
  error: string | null
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initialState: SearchInitialState<any> = {
  select: ESearchSelect.PROJECT,
  list: [],
  isLoading: false,
  error: null,
  show: false,
  count: 0,
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setShow(state, action) {
      return {
        ...state,
        show: action.payload,
      };
    },
    setSearchSelect(state, action) {
      return {
        ...state,
        select: action.payload,
      };
    },
    hiddenSearchList(state) {
      return {
        ...state,
        show: false,
      };
    },
    cleanList(state) {
      return {
        ...state,
        show: false,
        list: [],
        isLoading: false,
        error: null,
        count: 0,
      };
    },
  },
});
