import { ProfilProps } from '../modules/user/domain/user.entity';

export const getAccessToken = () => localStorage.getItem('accessToken');
export const setAccessToken = (value: string) => localStorage.setItem('accessToken', value);
export const deleteAccessToken = () => localStorage.removeItem('accessToken');
export const getAccessTokenRd = () => localStorage.getItem('rdToken');

export const getRefreshToken = () => localStorage.getItem('refreshToken');
export const setRefreshToken = (value: string) => localStorage.setItem('refreshToken', value);
export const deleteRefreshToken = () => localStorage.removeItem('refreshToken');

export const getUser = () => {
  if (localStorage.getItem('user') === null || localStorage.getItem('user') === 'undefined') {
    return null;
  }
  return JSON.parse(localStorage.getItem('user') ?? '');
};
export const setUser = (value: string) => localStorage.setItem('user', value);
export const deleteUser = () => localStorage.removeItem('user');

export const loginStorage = (
  { accessToken, refreshToken, user }: ProfilProps,
) => {
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
  setUser(JSON.stringify(user));
};

export const logoutStorage = () => {
  deleteAccessToken();
  deleteRefreshToken();
  deleteUser();
};
