/* eslint-disable react/jsx-props-no-spreading */
import { Dispatch, SetStateAction, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRefresh } from '../../../../hooks/useQuery';
import { alertMessage } from '../../../../libs/alertMessage';
import { handleUseCaseError } from '../../../../libs/ddd/errors/HandleUseCaseError';
import { ContactProps } from '../../../../modules/contact/domain/contact.entity';
import {
  CreateUserProps, ETeam, ListTeam, UpdateUserProps,
} from '../../../../modules/user/domain/user.entity';
import { USERS_LIST, useUserCreate } from '../../../../modules/user/queries/useUser';
import { InputSelectAsyncAddContact } from '../../Form/InputSelectAsyncAddContact';
import InputSelectMultiple from '../../Form/InputSelectMultiple';
import { SelectProject } from '../../Form/InputSelectSearchAsync/SelectProject';
import SaveButton from '../../shared/Buttons/SaveButton';
import { GenericModal } from '../../shared/Modals/GenericModal';
import { DivContainer, Wrapper } from '../style';
import { VerticalL } from '../verticalLine';
import { useFormUserHook, useToggleModal } from './hooks';

export interface UserModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  isRedirect?: boolean;
}

export function UserModal({
  open, setOpen, isRedirect,
}: UserModalProps) {
  const navigate = useNavigate();
  const refresh = useRefresh();

  useToggleModal(setOpen, open);

  const methods = useFormUserHook();

  const onSuccess = () => {
    refresh([USERS_LIST]);
  };

  const addCompany = useUserCreate(onSuccess);
  const onSubmit = (values: CreateUserProps) => {
    addCompany.mutateAsync(values)
      .then(({ id }) => {
        setOpen(false);
        if (isRedirect) {
          navigate(`/user/${id}`);
        }
      }).catch((error) => alertMessage.error(handleUseCaseError(error)));
  };
  const team = methods.watch('team');

  useEffect(() => {
    if (!team.includes(ETeam.ADMIN)) {
      methods.resetField('adminProjects');
    }
    if (!team.includes(ETeam.TECHNICIAN)) {
      methods.resetField('technicianProjects');
    }
  }, [methods, team]);
  return (
    <GenericModal
      title="Utilisateur"
      open={open}
      setOpen={setOpen}
    >
      <FormProvider
        {...methods}
      >

        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Wrapper>
            <DivContainer>
              <InputSelectAsyncAddContact<UpdateUserProps>
                label="Contact lié"
                name="contact"
                required
                rules={{
                  validate: (value: ContactProps) => {
                    if (!value.email) {
                      return "Ce contact ne possède pas d'email";
                    }
                    return true;
                  },
                }}
                multiple={false}
              />

            </DivContainer>
            <VerticalL />
            <DivContainer>
              <InputSelectMultiple
                label="Equipes"
                name="team"
                choices={ListTeam}
              />
              {team.includes(ETeam.ADMIN) ? (
                <SelectProject<UpdateUserProps>
                  label="Projet(s) admin lié(s)"
                  name="adminProjects"
                  multiple
                />
              ) : null}
              {team.includes(ETeam.TECHNICIAN) ? (
                <SelectProject<UpdateUserProps>
                  label="Projet(s) technicien lié(s)"
                  name="technicianProjects"
                  multiple
                />
              ) : null}
            </DivContainer>
          </Wrapper>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <SaveButton />
          </div>
        </form>
      </FormProvider>
    </GenericModal>
  );
}

UserModal.defaultProps = {
  isRedirect: false,
};
