/* eslint-disable react/require-default-props */
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import GenericButton, { GenericButtonProps } from '../GenericButton';

export default function IconAddButtonWithHoverEffect({ ...props }: GenericButtonProps) {
  return (
    <GenericButton size="small" icon={<AddCircleOutlineOutlinedIcon />} {...props} />
  );
}

IconAddButtonWithHoverEffect.defaultProps = {
  tooltipPlacement: 'bottom',
};
