import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CreateCompanyProps } from '../../../../modules/company/domain/company.entity';

export const useFormCompanyHook = (defaultValues?: Partial<CreateCompanyProps>) => useForm<CreateCompanyProps>({
  defaultValues: {
    ...defaultValues,
    email: undefined,
    socialReason: undefined,
    city: undefined,
    zipcode: undefined,
    streetNumber: undefined,
    streetName: undefined,
    phone: undefined,
    type: undefined,
    website: undefined,
    projects: undefined,
    contacts: undefined,
    comment: undefined,
  },
});

export const useToggleModal = (closeModal, open) => {
  useEffect(() => {
    if (!open) {
      closeModal(false);
    }
  }, [closeModal, open]);
};
