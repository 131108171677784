/* eslint-disable no-unused-vars */
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import boilerRoomInventoryTranslation from './Translations/French/boilerRoomInventoryTranslation.json';
import companyTranslation from './Translations/French/companyTranslation.json';
import consumptionEvolutionTranslation from './Translations/French/consumptionEvolutionTranslation.json';
import contactTranslation from './Translations/French/contactTranslation.json';
import errorsTranslation from './Translations/French/errorsTranslation.json';
import messagesTranslation from './Translations/French/messagesTranslation.json';
import projectTranslation from './Translations/French/projectTranslation.json';
import technicalReportTranslation from './Translations/French/technicalReportTranslation.json';
import userTranslation from './Translations/French/userTranslation.json';
import waltenr from './Translations/French/waltEnr.json';

export enum ENameSpaceTranslation {
  PROJECT = 'project',
  CONSUMPTION_EVOLUTION = 'consumptionEvolution',
  CONTACT = 'contact',
  COMPANY = 'company',
  USER = 'user',
  BOILER_ROOM_INVENTORY = 'boilerRoomInventory',
  TECHNICAL_REPORT = 'technicalReport',
  ERRORS = 'errors',
  MESSAGES = 'messages',
  WALTENR = 'waltenr',
}

const resources = {
  fr: {
    [ENameSpaceTranslation.PROJECT]: projectTranslation,
    [ENameSpaceTranslation.CONTACT]: contactTranslation,
    [ENameSpaceTranslation.COMPANY]: companyTranslation,
    [ENameSpaceTranslation.USER]: userTranslation,
    [ENameSpaceTranslation.BOILER_ROOM_INVENTORY]: boilerRoomInventoryTranslation,
    [ENameSpaceTranslation.TECHNICAL_REPORT]: technicalReportTranslation,
    [ENameSpaceTranslation.CONSUMPTION_EVOLUTION]: consumptionEvolutionTranslation,
    [ENameSpaceTranslation.ERRORS]: errorsTranslation,
    [ENameSpaceTranslation.MESSAGES]: messagesTranslation,
    [ENameSpaceTranslation.WALTENR]: waltenr,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: 'fr',
});

export default i18next;
