import {
  useMutation,
  UseMutationResult,
  UseQueryResult,
} from '@tanstack/react-query';
import { useQuery } from '../../../hooks/useQuery';
import {
  CountResponse,
  IdResponse,
  PaginateResponse,
} from '../../../libs/ddd/interfaces/base.interface';
import { OptionsQuery } from '../../../libs/interfaces';
import { ContactRepository } from '../api/contact.repository';
import {
  CreateContactProps,
  PaginateContactProps,
  UpdateContactProps,
} from '../domain/contact.entity';
import { ContactResponse } from '../responses/Contact.response.dto';
import { SearchContactsResponse } from '../responses/SearchContacts.response.dto';

export const CONTACTS_LIST = 'contactsList';
export const CONTACT_ONE = 'contact';
export const CONTACTS_LIST_SEARCH = 'contactsListSearch';
export const CONTACTS_COUNT = 'contactsCount';

export const useContactsListPaginate = ({
  page,
  limit,
  search,
  order,
  sort,
}: PaginateContactProps, options?: OptionsQuery): UseQueryResult<PaginateResponse<ContactResponse[]>, unknown> => useQuery(
  [CONTACTS_LIST, page, limit, search, order, sort],
  () => new ContactRepository().paginateContacts({
    page: page + 1, limit, search, order, sort,
  }),
  {
    ...options,
  },
);

export const useContactOne = ({
  id,
}): UseQueryResult<ContactResponse, unknown> => useQuery(
  [CONTACT_ONE, id],
  () => new ContactRepository().getOneContact(id as number),
);
export const useContactsListSearch = ({
  search,
  enabled,
}: {
  search?: string;
  enabled: boolean;
}): UseQueryResult<SearchContactsResponse[], unknown> => useQuery(
  [CONTACTS_LIST_SEARCH, search ?? ''],
  () => new ContactRepository().searchContacts(search ?? '', enabled),
  {
    refetchOnWindowFocus: false,
  },
);
export const useContactUpdate = (
  onSuccess,
): UseMutationResult<IdResponse, unknown, UpdateContactProps> => useMutation(
  (values: UpdateContactProps) => new ContactRepository().updateContact(values.id, values),
  {
    onSuccess,
  },
);
export const useContactsCount = (): UseQueryResult<CountResponse, unknown> => useQuery(
  [CONTACTS_COUNT],
  () => new ContactRepository().countContacts(),
);

export const useContactCreate = (): UseMutationResult<
IdResponse,
unknown,
CreateContactProps
> => useMutation((values: CreateContactProps) => new ContactRepository().createContact(values));

export const useContactRemove = (
  onSuccess,
): UseMutationResult<IdResponse, unknown, number> => useMutation((id: number) => new ContactRepository().removeContact(id), {
  onSuccess,
});
