export const handleAddress = (entity) => {
  if (entity.address) {
    return {
      ...entity,
      city: entity?.address?.properties.city ?? null,
      zipcode: entity?.address?.properties.postcode ?? null,
      streetNumber: entity?.address?.properties.housenumber ?? null,
      streetName: entity?.address?.properties.street ?? null,
    };
  }
  return entity;
};

export const reverseAddress = (project) => {
  const city = project?.city ?? '';
  const zipcode = project?.zipcode ?? '';
  const streetNumber = project?.streetNumber ?? '';
  const streetName = project?.streetName ?? '';
  return {
    label: `${streetNumber} ${streetName} ${city} ${zipcode}`,
    city,
    postcode: zipcode,
    housenumber: streetNumber,
    street: streetName,
  };
};
