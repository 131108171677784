import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import initializeTidioChat from './js/initializeTidioChat';
import './libs/i18next';
import App from './ui/App';
import GlobalStyle from './ui/utils/globalStyle';
import { walterreTheme } from './ui/utils/theme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
initializeTidioChat();

// React.StrictMode rendering twice in development but not in production.
// This help to find accidental side effects in the render phase.
root.render(
  <React.Suspense>
    <BrowserRouter>
      <ThemeProvider theme={walterreTheme}>
        <CssBaseline />
        <GlobalStyle />
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.Suspense>,
);
