/* eslint-disable no-unused-vars */
import { GenericChoices } from '../../../libs/ddd/interfaces/base.interface';
import { ProjectProps } from '../../project/domain/project.entity';

export enum ETermWorkCategory {
  CONFORMITY_AND_MAINTENANCE = 'conformityAndMaintenance',
  SHORT_TERM_WORK = 'shortTermWork',
  MEDIUM_TERM_WORK = 'mediumTermWork',
  LONG_TERM_WORK = 'longTermWork',
}

export const ListTermWorkCategory: GenericChoices<ETermWorkCategory>[] = [
  { label: 'Conformité & sécurité', value: ETermWorkCategory.CONFORMITY_AND_MAINTENANCE },
  { label: 'Actions court terme', value: ETermWorkCategory.SHORT_TERM_WORK },
  { label: 'Actions moyen terme', value: ETermWorkCategory.MEDIUM_TERM_WORK },
  { label: 'Actions long terme', value: ETermWorkCategory.LONG_TERM_WORK },
];

export interface FollowUpRecommendationProps {
  id: number;
  label: string;
  checked: boolean;
  termWorkCategory: ETermWorkCategory;
  project: ProjectProps | number | undefined;
  workspaceId: number;
}

export type CreateFollowUpRecommendationProps = Omit<FollowUpRecommendationProps, 'id' | 'workspaceId'>;
export type UpdateFollowUpRecommendationProps = FollowUpRecommendationProps;
