import { CountResponse, PaginateResponse } from '../../../libs/ddd/interfaces/base.interface';
import { EMethod, IQuery, UseAxios } from '../../../libs/useAxios';
import {
  ContactProps, CreateContactProps, PaginateContactProps, SearchContactsProps, UpdateContactProps,
} from '../domain/contact.entity';
import { ContactRepositoryPort } from './contact.repository.port';

export class ContactRepository implements ContactRepositoryPort {
  async createContact(contact: CreateContactProps): Promise<{ id: number; }> {
    const result = await new UseAxios<CreateContactProps, { id: number }>({
      method: EMethod.POST,
      url: '/contact',
      data: contact,
    }).exec();
    return result;
  }

  async getOneContact(id: number): Promise <ContactProps> {
    const result = await new UseAxios<{ id: number }, ContactProps>({
      method: EMethod.GET,
      url: '/contact',
      params: id.toString(),
    }).exec();
    return result;
  }

  async removeContact(id: number): Promise<{ id: number }> {
    const result = await new UseAxios<{ id: number; }, { id: number; }>({
      method: EMethod.DELETE,
      url: '/contact',
      params: id.toString(),
    }).exec();
    return result;
  }

  async paginateContacts({
    page, limit, search, order, sort,
  }: PaginateContactProps): Promise<PaginateResponse<ContactProps[]>> {
    const result = await new UseAxios<{ count: number }, PaginateResponse<ContactProps[]>>({
      method: EMethod.GET,
      url: '/contacts',
      query: [
        { page: page.toString() },
        { limit: limit.toString() },
        { order: order ?? 'createdAt' },
        { sort: sort ?? 'DESC' },
        search ? { search } : {},
      ],
    }).exec();
    return result;
  }

  async updateContact(id: number, contact: UpdateContactProps): Promise <{ id: number }> {
    const result = await new UseAxios<UpdateContactProps, { id:number }>({
      method: EMethod.PUT,
      url: `/contact/${id}`,
      data: contact,
    }).exec();
    return result;
  }

  async countContacts(): Promise<CountResponse> {
    const result = await new UseAxios<void, CountResponse>({
      method: EMethod.GET,
      url: '/contacts/count',
    }).exec();
    return result;
  }

  async searchContacts(search: string, enabled: boolean): Promise<SearchContactsProps[]> {
    if (!enabled) return [];
    const query: IQuery[] = [
      { page: '1' },
      { limit: '20' },
      { order: 'fullname' },
      { sort: 'ASC' },
      search ? { search } : {},
    ];
    const result = await new UseAxios<{ search: string }, PaginateResponse<SearchContactsProps[]>>({
      method: EMethod.GET,
      url: '/contacts',
      query,
    }).exec();
    return result.data;
  }
}
