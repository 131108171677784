import {
  useMutation,
  UseMutationResult,
  UseQueryResult,
} from '@tanstack/react-query';
import { useQuery } from '../../../hooks/useQuery';
import {
  CountResponse,
  IdResponse,
  PaginateResponse,
} from '../../../libs/ddd/interfaces/base.interface';
import { OptionsQuery } from '../../../libs/interfaces';
import { CompanyRepository } from '../api/company.repository';
import {
  CreateCompanyProps,
  PaginateCompanyProps,
  UpdateCompanyProps,
} from '../domain/company.entity';
import { CompanyResponse } from '../responses/Company.response.dto';
import { SearchCompaniesResponse } from '../responses/SearchCompanies.response.dto';

export const COMPANIES_LIST = 'companiesList';
export const COMPANY_ONE = 'Company';
export const COMPANIES_LIST_SEARCH = 'companiesListSearch';
export const COMPANIES_COUNT = 'companiesCount';

export const useCompaniesListPaginate = ({
  page, limit, order, sort, search, types,
}: PaginateCompanyProps, options?: OptionsQuery): UseQueryResult<PaginateResponse<CompanyResponse[]>, unknown> => useQuery(
  [COMPANIES_LIST, search, page, limit, order, sort, types?.join(',') ?? ''],
  () => new CompanyRepository().paginateCompanies({
    page: page + 1, limit, order, sort, search, types,
  }),
  {
    ...options,
  },
);

export const useCompanyOne = ({
  id,
}): UseQueryResult<CompanyResponse, unknown> => useQuery(
  [COMPANY_ONE, id],
  () => new CompanyRepository().getOneCompany(id as number),
);

export const useCompanyUpdate = (
  onSuccess,
): UseMutationResult<IdResponse, unknown, UpdateCompanyProps> => useMutation(
  (values: UpdateCompanyProps) => new CompanyRepository().updateCompany(values.id, values),
  {
    onSuccess,
  },
);

export const useCompaniesListSearch = ({
  search,
  enabled,
}: {
  search?: string;
  enabled: boolean;
}): UseQueryResult<SearchCompaniesResponse[], unknown> => useQuery(
  [COMPANIES_LIST_SEARCH, search ?? ''],
  () => new CompanyRepository().searchCompanies(search ?? '', enabled),
  {
    refetchOnWindowFocus: false,
  },
);

export const useCompaniesCount = (): UseQueryResult<CountResponse, unknown> => useQuery(
  [COMPANIES_COUNT],
  () => new CompanyRepository().countCompanies(),
);

export const useCompanyCreate = (): UseMutationResult<
IdResponse,
unknown,
CreateCompanyProps
> => useMutation((values: CreateCompanyProps) => new CompanyRepository().createCompany(values));

export const useCompanyRemove = (
  onSuccess,
): UseMutationResult<IdResponse, unknown, number> => useMutation((id: number) => new CompanyRepository().removeCompany(id), {
  onSuccess,
});
