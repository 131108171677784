import _ from 'lodash';
import i18, { ENameSpaceTranslation } from '../../i18next/index';

type ErrorWithMessage = {
  message: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object'
    && error !== null
    && 'message' in error
    && typeof (error as Record<string, unknown>).message === 'string'
  );
}

function isErrorAxiosWithResponse(error: unknown): boolean {
  return Boolean(_.get(error, 'response.data.message', false));
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorAxiosWithResponse(maybeError)) return new Error(_.get(maybeError, 'response.data.message'));
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

export const handleUseCaseError = (error: unknown) => i18.t(toErrorWithMessage(error).message, { ns: ENameSpaceTranslation.ERRORS });
