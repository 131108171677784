import { useState } from 'react';
import { ContactResponse } from '../../../../modules/contact/responses/Contact.response.dto';
import { ModalContact } from '../../modal/addContact';
import { InputSelectAsyncProps } from '../InputSelectSearchAsync';
import { SelectContact } from '../InputSelectSearchAsync/SelectContact';

type InputSelectAsyncAddContactProps<N> =
Pick<InputSelectAsyncProps<N, ContactResponse>,
'label' | 'required' | 'multiple' | 'name' | 'rules'>;

export function InputSelectAsyncAddContact<N>({
  label,
  required,
  name,
  rules,
  multiple,
}: InputSelectAsyncAddContactProps<N>) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
      }}
    >
      <SelectContact<N>
        name={name as keyof N}
        label={label}
        setOpenModal={setOpenModal}
        required={required}
        rules={rules}
        multiple={multiple}
        isNewOne
      />
      <ModalContact
        open={openModal}
        setOpen={setOpenModal}
        isRedirect={false}
      />
    </div>
  );
}

InputSelectAsyncAddContact.defaultProps = {};
