import axios from 'axios';
import { AddressesGouvProps } from './addresses.domain';
import { AddressesGouvRepositoryPort } from './adresses.repository.port';

export class AdressesGouvRepository implements AddressesGouvRepositoryPort {
  async addressesList({
    search,
  }: {search: string}): Promise<AddressesGouvProps> {
    try {
      const response = await axios.get(`https://api-adresse.data.gouv.fr/search/?q=${search}`);
      return response.data as AddressesGouvProps;
    } catch (err) {
      return err as any;
    }
  }
}
