import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
/** import local dayjs necessary */
import type { } from '@mui/lab/themeAugmentation';
import DayJS from 'dayjs';
import 'dayjs/locale/fr';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import '../assets/_normalize.scss';
import '../fonts/fonts.css';
import { Router } from '../libs/Router';
import { store } from '../libs/store';

DayJS.locale('fr');

function App() {
  const persistor = persistStore(store);
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider
      client={queryClient}
    >
      <Provider store={store}>

        <PersistGate
          loading={null}
          persistor={persistor}
        >
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <Router />
        </PersistGate>
        <ReactQueryDevtools initialIsOpen={false} />
      </Provider>
    </QueryClientProvider>
  );
}

export default (App);
