import { createGlobalStyle } from 'styled-components';
import '../../fonts/fonts.css';
import { Colors } from './colors';

const GlobalStyle = createGlobalStyle`
  #root{
      margin:0 auto;
  }
  
  h1 {
    color: ${Colors.grey};
  }

  h3 {
    color: ${Colors.black};
  }

  h4 {
    color: ${Colors.black};
  }
  
  p {
    color: ${Colors.black};
  }
    
`;

export default GlobalStyle;
