/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MatchedSolution } from '../../../ui/components/Project/CentralElement/OpportunityStudy/CompareRenewableSolutions/types';
import { OpportunityStudyRDProps } from '../domain/opportunity-study-rd.entity';

export type EViewType = 'notGenerated' | 'feasibility' | 'params' | 'selectSolutions' | 'validateSolutions' | 'synthesis';

export interface OpportunityStudyState {
  projectId: number | null;
  hasToken: boolean;
  data: OpportunityStudyRDProps | null;
  feasibility: any | null;
  matchedSolutions: MatchedSolution[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  view: EViewType;
}

export const initialState: OpportunityStudyState = {
  projectId: null,
  hasToken: false,
  data: null,
  feasibility: null,
  matchedSolutions: [],
  status: 'idle',
  error: null,
  view: 'notGenerated',
};

export const opportunityStudySlice = createSlice({
  name: 'opportunityStudy',
  initialState,
  reducers: {
    setView(state, action: PayloadAction<OpportunityStudyState['view']>) {
      state.view = action.payload;
    },
    setData(state, action: PayloadAction<OpportunityStudyState['data']>) {
      state.data = action.payload;
    },
    setStatus(state, action: PayloadAction<OpportunityStudyState['status']>) {
      state.status = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.status = 'failed';
    },
    setFeasibility(state, action: PayloadAction<OpportunityStudyState['feasibility']>) {
      state.feasibility = {
        ...state.feasibility,
        ...action.payload,
      };
      if (state.data) {
        state.data.currentBoilersToKeep = action.payload.currentBoilersToKeep;
      }
    },
    setMatchedSolutions(state, action: PayloadAction<OpportunityStudyState['matchedSolutions']>) {
      state.matchedSolutions = action.payload;
    },
    setHasToken(state, action: PayloadAction<boolean>) {
      state.hasToken = action.payload;
    },
    setProjectId(state, action: PayloadAction<number>) {
      state.projectId = action.payload;
    },
    setResetDatas(state) {
      return {
        ...initialState,
        projectId: state.projectId,
      };
    },
    reset: () => initialState,
  },
});

export const {
  setView, setData, setStatus, setError, setFeasibility, setMatchedSolutions, setHasToken, setProjectId, setResetDatas, reset,
} = opportunityStudySlice.actions;
