import { styled as styledMui } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Colors } from '../../utils/colors';

export const LogoStyle = styled.img`
  display: flex;
  max-height: 54px;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  margin-right: 40px;
`;

export const StyledLink = styledMui(Link)(() => ({
  textDecoration: 'none',
  color: 'black',
  '&:visited': {
    textDecoration: 'none',
  },
  '&:active': {
    textDecoration: 'none',
  },
}));

export const WaltappLinkStyled = styled(Link)`
  display: block;
  font-size: 0.9rem;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  color: ${Colors.walterreBlue};
  @media (min-width: 900px) {
    max-width: 10ch;
    width: 100%;
  }
  @media (max-width: 900px) {
    transform: translateX(-28px);
  }
`;
