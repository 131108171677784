/* eslint-disable react/jsx-props-no-spreading */
import {
  FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, SxProps,
} from '@mui/material';
import {
  Controller,
  RegisterOptions, useFormContext,
} from 'react-hook-form';

interface IChoices {
  label:string,
  value:string | number,
}

interface Props<T>{
  choices: IChoices[]
  required?: boolean;
  name: keyof T;
  rules?: RegisterOptions;
  row?: boolean;
  sx?: SxProps;
  label?: string;
}

export function RadioLoop<T>({
  choices, required, name, rules, row, sx, label,
}: Props<T>) {
  const { control } = useFormContext();
  return (
    <FormGroup>
      {label ? (
        <FormLabel>
          {label}
        </FormLabel>
      ) : null}
      <Controller
        render={({ field }) => (
          <RadioGroup
            onChange={field.onChange}
            defaultValue={field.value}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              ...sx,
            }}
            row={row}
          >
            { choices.map((el) => (
              <FormControlLabel
                key={el.value}
                value={el.value}
                control={<Radio />}
                label={el.label}
              />
            ))}
          </RadioGroup>
        )}
        name={name as string}
        control={control}
        rules={{
          ...rules,
          required: {
            value: required as boolean,
            message: 'Ce champ est obligatoire',
          },
        }}
      />
    </FormGroup>
  );
}

RadioLoop.defaultProps = {
  required: false,
  rules: {},
  row: true,
  sx: undefined,
  label: null,
};
