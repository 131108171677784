import styled from 'styled-components';
import { Colors } from '../../../../utils/colors';
import GenericButton from '../GenericButton';

export const CancelButtonStyled = styled(GenericButton)`
color: ${Colors.red} !important;
border-color: ${Colors.red} !important;
&:hover {
  border-color: ${Colors.red} !important;
  background-color: rgba(${Colors.red}, 0.04) !important;
}
`;
