/* eslint-disable react/require-default-props */
import { GenericButtonProps } from '../GenericButton';
import { CancelButtonStyled } from './style';

export default function CancelButton({
  ...props
}: GenericButtonProps) {
  return (
    <CancelButtonStyled variant="outlined" size="small" {...props} />
  );
}

CancelButton.defaultProps = {
  label: 'Annuler',
};
