import { ToastOptions, toast } from 'react-toastify';

const options: ToastOptions = {
  theme: 'light',
  style: { fontFamily: 'Montserrat, serif', fontSize: '1rem', whiteSpace: 'pre-line' }, // Added pre-line for newline support
};

export const alertMessage = (() => {
  const error = (message) => toast.error(message, options);
  const success = (message) => toast.success(message, options);
  const warning = (message) => toast.warning(message, options);
  const info = (message) => toast.info(message, options);

  return {
    error,
    success,
    warning,
    info,
  };
})();
