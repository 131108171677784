import { Dispatch, SetStateAction, useState } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { InputSelectSearchAsync } from '.';
import { ESort } from '../../../../libs/interfaces';
import { useContactsListPaginate } from '../../../../modules/contact/queries/useContacts';
import { ContactResponse } from '../../../../modules/contact/responses/Contact.response.dto';
import { EStyleSelect } from '../SelectInput';

export interface SelectContactProps<FieldName> {
  label: string;
  name: keyof FieldName;
  required?: boolean;
  rules?: RegisterOptions;
  multiple?: boolean;
  isNewOne?: boolean;
  selectStyle?: EStyleSelect;
  setOpenModal?: Dispatch<SetStateAction<boolean>>;
}

export function SelectContact<FieldName>({
  label, name, required, rules, multiple, isNewOne, selectStyle, setOpenModal,
}: SelectContactProps<FieldName>) {
  const [search, setSearch] = useState('');
  const { data, isLoading } = useContactsListPaginate({
    page: 0, limit: 15, search, order: 'fullname', sort: ESort.ASC,
  }, { keepPreviousData: false, enabled: !!search });

  return (
    <InputSelectSearchAsync<FieldName, ContactResponse>
      label={label}
      name={name}
      getOptionLabel={(option) => option.fullname as string}
      getOptionValue={(option) => option.id as unknown as string}
      data={data?.data ?? []}
      isLoading={isLoading}
      search={search}
      setSearch={setSearch}
      required={required}
      rules={rules}
      multiple={multiple}
      isNewOne={isNewOne}
      selectStyle={selectStyle}
      setOpenModal={setOpenModal}
    />
  );
}

SelectContact.defaultProps = {
  required: false,
  rules: {},
  multiple: false,
  isNewOne: false,
  setOpenModal: undefined,
  selectStyle: 'top',
};
