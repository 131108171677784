import { Box } from '@mui/material';
import { ReactElement } from 'react';
import CancelButton from '../Buttons/CancelButton';
import GenericButton from '../Buttons/GenericButton';
import {
  FormWrapperStyle, FormWrapperStyleRemark, GenericButtonFormWrapperStyle, WrapperInputTooltipStyle, WrapperLabelTooltipStyle, WrapperStyle,
} from './style';

interface WrapperProps {
  children: ReactElement | ReactElement[]
}

interface SaveCancelWrapperProps {
  onClickCancel: () => void;
  onClickSave: () => void;
}

export function Wrapper({ children }: WrapperProps) {
  return (
    <WrapperStyle>
      {children}
    </WrapperStyle>
  );
}

export function FormWrapper({ children }: WrapperProps) {
  return (
    <FormWrapperStyle>
      <Box
        sx={{
          width: '100%',
          maxWidth: { xs: 'unset', md: '500px' },
          margin: '0 auto',
        }}
      >
        {children}
      </Box>
    </FormWrapperStyle>
  );
}

export function GenericButtonFormWrapper({ children }: WrapperProps) {
  return (
    <GenericButtonFormWrapperStyle>
      {children}
    </GenericButtonFormWrapperStyle>
  );
}

export function SaveAndCancelWrapper({ onClickCancel, onClickSave }: SaveCancelWrapperProps) {
  return (
    <GenericButtonFormWrapper>
      <CancelButton
        onClick={onClickCancel}
      />
      <GenericButton
        onClick={onClickSave}
        label="Enregistrer"
        size="small"
      />
    </GenericButtonFormWrapper>
  );
}

export function FormWrapperRemark({ children }: WrapperProps) {
  return (
    <FormWrapperStyleRemark>
      {children}
    </FormWrapperStyleRemark>
  );
}

export function WrapperInputTooltip({ children }: WrapperProps) {
  return (
    <WrapperInputTooltipStyle>
      {children}
    </WrapperInputTooltipStyle>
  );
}

export function WrapperLabelTooltip({ children }: WrapperProps) {
  return (
    <WrapperLabelTooltipStyle>
      {children}
    </WrapperLabelTooltipStyle>
  );
}
