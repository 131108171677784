/* eslint-disable no-unused-vars */
import weatherStations from '../../../jsons/weather-stations.json';
import { GenericChoices } from '../../../libs/ddd/interfaces/base.interface';
import { PaginateProps, SearchProps, SortProps } from '../../../libs/interfaces';
import { ActivityReportProps } from '../../activityReport/domain/activityReport.entity';
import { AnnualDatasProps } from '../../annual-datas/domain/annual-datas.entity';
import { FeaturesAddressesProps } from '../../api-gouv/addresses.domain';
import {
  CompanyProps,
  CompanyWithoutCompanyInContactsProps,
} from '../../company/domain/company.entity';
import {
  ContactProps,
  ContactPropsWithoutCompany,
} from '../../contact/domain/contact.entity';
import { DocumentsProps } from '../../documents/domain/document.entity';
import { EnergyContractProps } from '../../energy-contract/domain/energy-contract.entity';
import { FollowUpGroupProps } from '../../follow-up-groups/domain/follow-up.entity';
import { ETermWorkCategory, FollowUpRecommendationProps } from '../../follow-up-recommendation/domain/follow-up.entity';
import { IndividualCostHeatingContractProps } from '../../individual-cost-heating-contract/domain/individual-cost-heating-contract.entity';
import { InitialFinancialReportProps } from '../../initialReport/domain/initialReport.entity';
import { MaintenanceContractProps } from '../../maintenance-contract/domain/maintenance-contract.entity';
import { OpportunityStudyProps } from '../../opportunity-study/domain/opportunity-study.entity';
import { ReportTechnicalProps } from '../../technical-report/domain/technical-report.entity';
import { TransformationProps } from '../../transformation/domain/transformation.entity';
import { UserProps } from '../../user/domain/user.entity';

export enum EPhase {
  TO_PROGRAM = 'toProgram',
  INTERVENTION_TO_BE_PERFORMED = 'interventionToBePerformed',
  INTERVENTION_PERFORMED = 'interventionPerformed',
  REPORTS_TO_BE_FINALIZED = 'reportsToBeFinalized',
  COMMISSIONING = 'commissioning',
  FINISHED = 'finished',
}

export enum EEnergy {
  NATURAL_GAS = 'naturalGas',
  OIL = 'oil',
  RCU = 'rcu',
  BUTANE = 'butane',
  PROPANE = 'propane',
  WOOD_PELLETS = 'woodpellets',
  WOOD_CHIPS = 'woodChips',
  ELECTRICITY = 'electricity',
  GREEN_GAS = 'greenGas',
  BIO_PROPANE = 'biopropane',
}

export enum ENatureOfEcsProduction {
  INDIVIDUAL = 'individual',
  SHARED = 'shared',
  SEPARATED = 'separated',
}

export enum EBoilerRoomKeys {
  STANDARDIZED_KEY_BOX = 'standardizedKeyBox',
  MANAGER = 'manager',
  TECHNICAL_OFFICER = 'technicalOfficer',
  OWNER = 'owner',
  OTHER = 'other',
}
export enum EPropertyType {
  RESIDENTIAL = 'residential',
  TERTIARY = 'tertiary',
}

export const ListPropertyType: GenericChoices<EPropertyType>[] = [
  {
    label: 'Résidentiel',
    value: EPropertyType.RESIDENTIAL,
  },
  {
    label: 'Tertiaire',
    value: EPropertyType.TERTIARY,
  },
];

export enum EHabitantType {
  MIXED = 'mixed',
  ACTIVE = 'active',
  SEDENTARY = 'sedentary',
  SOCIAL = 'social',
}

export const ListHabitantType: GenericChoices<EHabitantType>[] = [
  {
    label: 'Actif',
    value: EHabitantType.ACTIVE,
  },
  {
    label: 'Mixte',
    value: EHabitantType.MIXED,
  },
  {
    label: 'Sédentaire',
    value: EHabitantType.SEDENTARY,
  },
  {
    label: 'Social',
    value: EHabitantType.SOCIAL,
  },
];

export enum EBuildingType {
  EHPAD = 'ehpad',
  OFFICES = 'offices',
  SCHOOL = 'school',
  BUSINESS = 'business',
  HOTEL = 'hotel',
  PUBLIC = 'public',
  RELIGIOUS = 'religious',
}

export const ListBuildingType: GenericChoices<EBuildingType>[] = [
  {
    label: 'Commerce',
    value: EBuildingType.BUSINESS,
  },
  {
    label: 'EHPAD',
    value: EBuildingType.EHPAD,
  },
  {
    label: 'Hôtel',
    value: EBuildingType.HOTEL,
  },
  {
    label: 'Bureaux',
    value: EBuildingType.OFFICES,
  },
  {
    label: 'Publique',
    value: EBuildingType.PUBLIC,
  },
  {
    label: 'Eglise',
    value: EBuildingType.RELIGIOUS,
  },
  {
    label: 'Établissement scolaire',
    value: EBuildingType.SCHOOL,
  },
];

export const ListPhase: GenericChoices<EPhase>[] = [
  {
    value: EPhase.TO_PROGRAM,
    label: 'Intervention à programmer',
  },
  {
    value: EPhase.INTERVENTION_TO_BE_PERFORMED,
    label: 'Intervention à effectuer',
  },
  {
    value: EPhase.INTERVENTION_PERFORMED,
    label: 'Intervention effectuée',
  },
  {
    value: EPhase.REPORTS_TO_BE_FINALIZED,
    label: 'Rapports d’expertise à finaliser',
  },
  {
    value: EPhase.COMMISSIONING,
    label: 'AMEx',
  },
  {
    value: EPhase.FINISHED,
    label: 'Terminé',
  },
];

export const ListBoilerRoomKeys: GenericChoices<EBoilerRoomKeys>[] = [
  {
    value: EBoilerRoomKeys.STANDARDIZED_KEY_BOX,
    label: 'Boîte à clef standardisée',
  },
  {
    value: EBoilerRoomKeys.MANAGER,
    label: 'Gestionnaire',
  },
  {
    value: EBoilerRoomKeys.OWNER,
    label: 'Propriétaire',
  },
  {
    value: EBoilerRoomKeys.TECHNICAL_OFFICER,
    label: 'Agent technique',
  },
  {
    value: EBoilerRoomKeys.OTHER,
    label: 'Autre',
  },
];
export interface BuildingProps {
  name: string;
  propertyType: string | undefined;
  habitantType: string | undefined;
  buildingType: string | undefined;
  numberLot: number | undefined;
  numberBed: number | undefined;
  numberRoom: number | undefined;
  shab: number | undefined;
  constructionYear: number | undefined;
  heatingLevel: number | undefined;
  closeMonthStateExpenses: string | undefined;
}

export interface RremarkEnergyExpensesProps {
  description: string | undefined;
  showInReport: boolean | undefined;
}

export enum EBillingPost {
  P1 = 'p1',
  P2 = 'p2',
  P3 = 'p3',
  P4 = 'p4',
}
export enum EContractMaintenanceType {
  FUEL_AND_SERVICE = 'fuelAndService',
  COUNTING_MARKET = 'countingMarket',
  TEMPERATURE_MARKET = 'temperatureMarket',
  MARKET_AND_PACKAGE = 'marketAndPackage',
  SERVICE_AND_PACKAGE = 'serviceAndPackage',
}

export enum ECountingType {
  CET = 'cet',
  RFC = 'rfc',
}

export enum EInterventionType {
  SHORTENED_SUPPORT = 'shortenedSupport',
  COMPLETE_SUPPORT = 'completeSupport',
}

export const ListInterventionType: {
  value: EInterventionType;
  label: string;
}[] = [
  {
    value: EInterventionType.COMPLETE_SUPPORT,
    label: 'Prise en charge expert',
  },
  { value: EInterventionType.SHORTENED_SUPPORT, label: 'Prise en charge' },
];

export const ListCountingType: { value: ECountingType; label: string }[] = [
  { value: ECountingType.CET, label: "Compteur d'Energie Thermique (CET)" },
  {
    value: ECountingType.RFC,
    label: 'Répartiteur de Frais de Chauffage (RFC)',
  },
];

export const ListEnergy: { value: EEnergy; label: string }[] = [
  { value: EEnergy.NATURAL_GAS, label: 'Gaz naturel' },
  { value: EEnergy.OIL, label: 'Fioul' },
  { value: EEnergy.RCU, label: 'RCU' },
  { value: EEnergy.BUTANE, label: 'Butane' },
  { value: EEnergy.PROPANE, label: 'Propane' },
  { value: EEnergy.WOOD_PELLETS, label: 'Bois Granulés' },
  { value: EEnergy.WOOD_CHIPS, label: 'Bois Plaquettes' },
  { value: EEnergy.ELECTRICITY, label: 'Électricité' },
  { value: EEnergy.GREEN_GAS, label: 'Biométhane' },
  { value: EEnergy.BIO_PROPANE, label: 'Biopropane' },
];

export const ListNatureOfEcsProduction: {
  value: ENatureOfEcsProduction;
  label: string;
}[] = [
  { value: ENatureOfEcsProduction.SHARED, label: 'Collective partagée' },
  { value: ENatureOfEcsProduction.INDIVIDUAL, label: 'Individuelle' },
  { value: ENatureOfEcsProduction.SEPARATED, label: 'Collective séparée' },
];

export const ListBillingPost: { value: EBillingPost; label: string }[] = [
  { value: EBillingPost.P1, label: 'P1' },
  { value: EBillingPost.P2, label: 'P2' },
  { value: EBillingPost.P3, label: 'P3' },
  { value: EBillingPost.P4, label: 'P4' },
];

export const ListContractMaintenanceType: {
  value: EContractMaintenanceType;
  label: string;
}[] = [
  {
    value: EContractMaintenanceType.FUEL_AND_SERVICE,
    label: 'Combustible et Prestation',
  },
  { value: EContractMaintenanceType.COUNTING_MARKET, label: 'Marché Comptage' },
  {
    value: EContractMaintenanceType.MARKET_AND_PACKAGE,
    label: 'Marché et Forfait',
  },
  {
    value: EContractMaintenanceType.TEMPERATURE_MARKET,
    label: 'Marché Température',
  },
  {
    value: EContractMaintenanceType.SERVICE_AND_PACKAGE,
    label: 'Prestation et Forfait',
  },
];

export const ListWeatherStation: {
  value: string;
  label: string;
}[] = weatherStations
  .map((station) => ({ value: station.name, label: station.name }))
  .sort((a, b) => (a.label < b.label ? -1 : 1));

export interface ReferenceValuesProps {
  consumptionIndexOnLastPeriod: boolean;
  annualConsumptionChart: string;
  annualExpenses: number;
  consumptionIndex: number;
  annualtCo2Emissions: number;
  annualConsumptionTarget: number;
  annualPotentialGain: number;
  annualPotentialCo2Reduction: number;
  annualExpensesPerM2: number;
  annualCarsEmissions:number;
  consumptionIndexPerM2: number;
}

export interface PerformanceTrackingProps {
  referencePeriodDju: number;
  reference10yearsDju: number;
  heatingPerformanceIndex: number;
  annualConsumptionReference: number;
  annualConsumptionTarget: number;
  ecsConsumptionRef: number;
  project: ProjectProps | number | undefined;
}

export interface SizingProps {
  theoreticalEcspower: number;
  theoreticalHeatingPower: number;
  theoreticalBoilerRoomPower: number;
  installedBoilerTotalNominalPower: number;
  boilerRoomPowerChart: string;
}

export interface FollowUpRecommendationsOrderProps {
  [ETermWorkCategory.CONFORMITY_AND_MAINTENANCE]: number[];
  [ETermWorkCategory.SHORT_TERM_WORK]: number[];
  [ETermWorkCategory.MEDIUM_TERM_WORK]: number[];
  [ETermWorkCategory.LONG_TERM_WORK]: number[];
}

export interface ProjectProps {
  transformation: TransformationProps;
  id: number;
  name: string;
  interventionType: EInterventionType | undefined;
  phase: string | undefined;
  interventionDate: string | null;
  city: string | undefined;
  zipcode: string | undefined;
  streetNumber: string | undefined;
  streetName: string | undefined;
  natureOfEcsProduction: string | undefined;
  contacts: ContactProps[] | undefined;
  contactsId?: number[] | undefined;
  boilerRoomKeys?: EBoilerRoomKeys;
  annualEnergyExpenses: AnnualDatasProps[] | undefined;
  maintenanceContract: MaintenanceContractProps[] | undefined;
  buildings: BuildingProps[] | undefined;
  energyContracts: EnergyContractProps[] | undefined;
  individualCostHeatingContract: IndividualCostHeatingContractProps | undefined;
  onSiteContact?: ContactProps | undefined;
  admin?: UserProps | undefined;
  technician: UserProps | undefined;
  companies: CompanyProps[] | undefined;
  boilerRoomInventory: { id: number };
  initialFinancialReport: InitialFinancialReportProps | undefined;
  weatherStation: string;
  remarkEnergyExpenses: RremarkEnergyExpensesProps[] | undefined;
  technicalReport: ReportTechnicalProps;
  comment: string;
  createdAt?: Date;
  virtualVisitLink: string;
  referentEnergy?: ContactProps | undefined;
  documents: DocumentsProps[];
  followUpRecommendations: FollowUpRecommendationProps[] | undefined;
  followUpGroups: FollowUpGroupProps[] | undefined;
  isReportFinalized: boolean;
  address?: FeaturesAddressesProps;
  latitude: number | undefined;
  longitude: number | undefined;
  referencePeriodDju: number | undefined;
  totalEstimatedBoilerPower: number | undefined;
  referenceValues: ReferenceValuesProps | undefined;
  performanceTracking: PerformanceTrackingProps | undefined;
  sizing: SizingProps | undefined;
  followUpRecommendationsOrder: FollowUpRecommendationsOrderProps;
  ventilationInventory: any | number;
  airConditioningInventory: any | number;
}

export type ProjectsListProps = Pick<ProjectProps, 'name' | 'phase' | 'id' | 'technician' | 'admin' | 'companies' | 'createdAt'>;
export type GetOneProjectProps = ProjectProps & {
  annualEnergyExpenses: number[] | undefined;
  activityReport: ActivityReportProps[];
  opportunityStudy: Omit<OpportunityStudyProps, 'geothermalMap' | 'heatingDistrict'>;
  maintenanceContract: number[] | undefined;
  energyContracts: number[] | undefined;
  individualCostHeatingContract: number | undefined;
  boilerRoomInventory: { id: number, generalInformations: { visitInformations: { buildingPic: { url: string } } }, generators: { generatorsOverview: { boilerTotalNominalPower: number } } };
  ventilationInventory: number;
  airConditioningInventory: number;
  initialFinancialReport: number | undefined;
  weatherStation: string;
  remarkEnergyExpenses: number[] | undefined;
  technicalReport: number;
  followUpRecommendations: number[] | undefined;
  followUpGroups: number[] | undefined;
  referenceValues: number | undefined;
  performanceTracking: number | undefined;
  sizing: number | undefined;
  checkedSections: string[];
  followUpRecommendationsOrder: number;
};
export type CreateProjectProps = Pick<
  ProjectProps,
  | 'name'
  | 'phase'
  | 'interventionDate'
  | 'interventionType'
  | 'boilerRoomKeys'
  | 'streetName'
  | 'streetNumber'
  | 'city'
  | 'zipcode'
  | 'buildings'
  | 'address'
> & {
  onSiteContact?: ContactPropsWithoutCompany | undefined;
  admin?: ContactPropsWithoutCompany | undefined;
};

export type CreateMaintenanceContractProps = Omit<
  MaintenanceContractProps,
  'id'
>;
export type UpdateProjectProps = ProjectProps;
export interface UpdateProjectInitialFinancialReportProps
  extends InitialFinancialReportProps {
  annualEnergyExpenses: AnnualDatasProps[];
}

export type UpdateProjectContractProps = {
  id: number;
  individualCostHeatingContract?: IndividualCostHeatingContractProps | null;
  maintenanceContract: CreateMaintenanceContractProps[] | MaintenanceContractProps[] | undefined;
  energyContracts: EnergyContractProps[] | undefined;
  individualCostHeatingContractActive: boolean;
  companies: CompanyWithoutCompanyInContactsProps[] | undefined;
};

export type UpdateProjectCompaniesProps = Pick<
ProjectProps,
| 'id'
| 'companies'
>;

export type UpdateProjectBuildingProps = Pick<
ProjectProps,
| 'id'
| 'buildings'
| 'city'
| 'zipcode'
| 'streetName'
| 'streetNumber'
| 'weatherStation'
| 'referentEnergy'
| 'latitude'
| 'longitude'
| 'address'
>;
// Add technician
type AdministratorSocialReason = Pick<CompanyProps, 'socialReason'>;
type PartialSearchProjectsProps = Pick<
  ProjectProps,
  'name' | 'phase' | 'id' | 'technician'
>;
export type SearchProjectsProps = PartialSearchProjectsProps &
  AdministratorSocialReason;
export type ProjectPropsInCompany = Pick<
  ProjectProps,
  'id' | 'name' | 'phase' | 'interventionDate'
>;
export type StatsPhaseProjectProps = {
  technician?: number;
  admin?: number;
};

export type StatsPhaseProjectResponse = {
  phase: EPhase;
  total: number;
};
export type PaginateProjectProps = PaginateProps & Partial<SortProps<ProjectProps>> & Partial<SearchProps>
