import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CreateUserProps } from '../../../../modules/user/domain/user.entity';

export const useFormUserHook = () => useForm<CreateUserProps>({
  defaultValues: {
    contact: undefined,
    team: [],
    technicianProjects: [],
    adminProjects: [],
  },
});

export const useToggleModal = (setOpen, open) => {
  useEffect(() => {
    if (!open) {
      setOpen(false);
    }
  }, [setOpen, open]);
};
