/* eslint-disable react/jsx-props-no-spreading */
import { Dispatch, SetStateAction } from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRefresh } from '../../../../hooks/useQuery';
import { alertMessage } from '../../../../libs/alertMessage';
import { handleUseCaseError } from '../../../../libs/ddd/errors/HandleUseCaseError';
import {
  CompanyProps, CreateCompanyProps, ListType,
} from '../../../../modules/company/domain/company.entity';
import { useCompanyCreate } from '../../../../modules/company/queries/useCompanies';
import { InputAddress } from '../../Form/InputAddress';
import { handleAddress } from '../../Form/InputAddress/utils';
import { SelectContact } from '../../Form/InputSelectSearchAsync/SelectContact';
import { SelectProject } from '../../Form/InputSelectSearchAsync/SelectProject';
import { InputText } from '../../Form/InputText';
import SelectInput from '../../Form/SelectInput';
import SaveButton from '../../shared/Buttons/SaveButton';
import { GenericModal } from '../../shared/Modals/GenericModal';
import { DivContainer, Wrapper } from '../style';
import { VerticalL } from '../verticalLine';
import { useFormCompanyHook, useToggleModal } from './hooks';

export interface ModalCompanyProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  isRedirect?: boolean;
  defaultValues?: Partial<CreateCompanyProps>;
}

export function ModalCompany({
  open, setOpen, isRedirect, defaultValues,
}: ModalCompanyProps) {
  const navigate = useNavigate();
  const refresh = useRefresh();

  useToggleModal(setOpen, open);

  const methods = useFormCompanyHook(defaultValues);
  const addCompany = useCompanyCreate();
  const onSubmit = (values: CreateCompanyProps) => {
    addCompany.mutateAsync(handleAddress(values))
      .then(({ id }) => {
        refresh(['companiesList']);
        setOpen(false);
        if (isRedirect) {
          navigate(`/company/${id}`);
        }
      }).catch((error) => alertMessage.error(handleUseCaseError(error)));
  };
  return (
    <GenericModal
      title="Entreprise"
      open={open}
      setOpen={setOpen}
    >
      <FormProvider
        {...methods}
      >

        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Wrapper>
            <DivContainer>
              <InputText
                name="socialReason"
                label="Raison sociale"
                required
              />
              <SelectInput<CompanyProps>
                name="type"
                label="Type d'entreprise"
                required
                choices={ListType}
              />
              <InputAddress />
              <InputText<CompanyProps>
                name="email"
                label="Email"
                rules={{
                  pattern: {
                    value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                    message: 'adresse email invalide',
                  },
                }}
              />
            </DivContainer>
            <VerticalL />
            <DivContainer>
              <SelectContact<CompanyProps>
                label="Contact(s) lié(s)"
                name="contacts"
                multiple
              />
              <SelectProject<CompanyProps>
                label="Projet(s) lié(s)"
                name="projects"
                multiple
              />
              <InputText<CompanyProps>
                name="website"
                label="Site web"
              />
              <InputText<CompanyProps>
                name="phone"
                label="N° de téléphone"
                rules={{
                  pattern: {
                    value: /[0-9]/,
                    message: 'Doit contenir seulement des chiffres',
                  },
                  minLength: {
                    value: 10,
                    message: 'Format invalide',
                  },
                  maxLength: {
                    value: 10,
                    message: 'Format invalide',
                  },
                }}
              />
            </DivContainer>
          </Wrapper>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <SaveButton />
          </div>
        </form>
      </FormProvider>
    </GenericModal>
  );
}

ModalCompany.defaultProps = {
  isRedirect: false,
  defaultValues: undefined,
};
