/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

export enum ETypeList {
  PROJECT = 'project',
  COMPANY = 'company',
  CONTACT = 'contact',
}

export interface PaginationInitialState {
  page: number;
  limit: number;
  type?: ETypeList;
}

export const initialState: PaginationInitialState = {
  page: 0,
  limit: 25,
  type: undefined,
};

export const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    setPage(state, action) {
      return {
        ...state,
        page: action.payload,
      };
    },
    setLimit(state, action) {
      return {
        ...state,
        limit: action.payload,
      };
    },
    reset(state, action) {
      return {
        ...state,
        type: action.payload,
        page: 0,
        limit: 25,
      };
    },
  },
});
