import {
  useMutation,
  UseMutationResult,
  UseQueryResult,
} from '@tanstack/react-query';
import { useQuery } from '../../../hooks/useQuery';
import {
  CountResponse,
  IdResponse,
  PaginateResponse,
} from '../../../libs/ddd/interfaces/base.interface';
import { OptionsQuery } from '../../../libs/interfaces';
import { ProjectRepository } from '../api/project.repository';
import {
  BuildingProps,
  CreateProjectProps,
  GetOneProjectProps,
  PaginateProjectProps,
  ProjectsListProps,
  StatsPhaseProjectProps,
  StatsPhaseProjectResponse,
  UpdateProjectBuildingProps,
  UpdateProjectCompaniesProps,
  UpdateProjectContractProps,
  UpdateProjectProps,
} from '../domain/project.entity';
import { ProjectResponse } from '../responses/project.response';
import { SearchProjectsResponse } from '../responses/Search-project.response';

export const PROJECTS_LIST = 'projectsList';
export const PROJECTS_LIST_SEARCH = 'projectsListSearch';
export const PROJECTS_ONE = 'project';
export const PROJECTS_COUNT = 'projectsCount';
export const PROJECTS_EDL = 'projectEdl';
export const PROJECTS_PHASE_STATS = 'projectsPhaseStats';
export const PROJECTS_EDL_SIMPLIFIED = 'projectSimplified';
export const MATERIAL_CSV = 'materialCsv';
export const MATERIAL_JSON = 'materialJson';
export const MATERIAL_XLSX = 'materialXlsx';

export const useProjectsListPaginate = ({
  page, limit, search, order, sort,
}: PaginateProjectProps, options: OptionsQuery): UseQueryResult<PaginateResponse<ProjectsListProps[]>, unknown> => useQuery(
  [PROJECTS_LIST, page, limit, search, sort, order],
  () => new ProjectRepository().paginateProjects({
    page: page + 1, limit, search, order, sort,
  }),
  options,
);

export const useProjectsListSelect = (options: OptionsQuery): UseQueryResult<ProjectResponse[], unknown> => useQuery(
  [PROJECTS_LIST],
  () => new ProjectRepository().getAllProjectSelect(),
  options,
);

export const useProjectOne = ({
  id,
}): UseQueryResult<GetOneProjectProps, IdResponse> => useQuery(
  [PROJECTS_ONE, parseInt(id, 10)],
  () => new ProjectRepository().getOneProject(id as number),
);

export const useProjectEdl = ({ id }): UseQueryResult<string, IdResponse> => useQuery(
  [PROJECTS_EDL, id],
  () => new ProjectRepository().getEdl(id as number),
  {
    refetchOnWindowFocus: false,
    enabled: false,
    cacheTime: 0,
  },
);

export const useProjectPec = ({ id }): UseQueryResult<string, IdResponse> => useQuery(
  [PROJECTS_EDL_SIMPLIFIED, id],
  () => new ProjectRepository().getPec(id as number),
  {
    refetchOnWindowFocus: false,
    enabled: false,
    cacheTime: 0,
  },
);
export const useProjectMaterialCsv = ({ id }): UseQueryResult<string, IdResponse> => useQuery(
  [MATERIAL_CSV, id],
  () => new ProjectRepository().getMaterialCsv(id as number),
  {
    refetchOnWindowFocus: false,
    enabled: false,
    cacheTime: 0,
  },
);
export const useProjectMaterialJson = ({ id }): UseQueryResult<string, IdResponse> => useQuery(
  [MATERIAL_CSV, id],
  () => new ProjectRepository().getMaterialJson(id as number),
  {
    refetchOnWindowFocus: false,
    enabled: false,
    cacheTime: 0,
  },
);
export const useProjectMaterialXlsx = ({ id }): UseQueryResult<string, IdResponse> => useQuery(
  [MATERIAL_XLSX, id],
  () => new ProjectRepository().getMaterialXlsx(id as number),
  {
    refetchOnWindowFocus: false,
    enabled: false,
    cacheTime: 0,
  },
);

export const useProjectsListSearch = ({
  search,
  enabled,
}: {
  search: string;
  enabled: boolean;
}): UseQueryResult<SearchProjectsResponse[], unknown> => useQuery(
  [PROJECTS_LIST_SEARCH, search],
  () => new ProjectRepository().searchProjects(search ?? '', enabled),
  {
    refetchOnWindowFocus: false,
  },
);

export const useProjectsCount = (): UseQueryResult<CountResponse, unknown> => useQuery(
  [PROJECTS_COUNT],
  () => new ProjectRepository().countProjects(),
);

export const useProjectCreate = (): UseMutationResult<
IdResponse,
unknown,
CreateProjectProps
> => useMutation((values: CreateProjectProps) => new ProjectRepository().createProject(values));

export const useBuildingCreate = (): UseMutationResult<
IdResponse,
unknown,
BuildingProps
> => useMutation((values: BuildingProps) => new ProjectRepository().createBuilding(values));

export const useProjectUpdate = (
  onSuccess,
): UseMutationResult<IdResponse, unknown, UpdateProjectProps> => useMutation(
  (values: UpdateProjectProps) => new ProjectRepository().updateProject(values.id, values),
  {
    onSuccess,
  },
);

export const useProjectCompaniesUpdate = (
  onSuccess,
): UseMutationResult<
IdResponse,
unknown,
{ id: number; values: UpdateProjectCompaniesProps }
> => useMutation(
  ({ id, values }: { id: number; values: UpdateProjectCompaniesProps }) => new ProjectRepository().updateProjectCompanies(id, values),
  {
    onSuccess,
  },
);

export const useProjectContractsUpdate = (
  onSuccess,
): UseMutationResult<
IdResponse,
unknown,
{ id: number; values: UpdateProjectContractProps }
> => useMutation(
  ({ id, values }: { id: number; values: UpdateProjectContractProps }) => new ProjectRepository().updateProjectContracts(id, values),
  {
    onSuccess,
  },
);

export const useProjectBuildingInfoUpdate = (
  onSuccess,
): UseMutationResult<
IdResponse,
unknown,
{ id: number; values: UpdateProjectBuildingProps }
> => useMutation(
  ({ id, values }: { id: number; values: UpdateProjectBuildingProps }) => new ProjectRepository().updateProjectBuildingInformation(id, values),
  {
    onSuccess,
  },
);

export const useProjectAnnualEnergyUpdate = (
  onSuccess,
): UseMutationResult<
IdResponse,
unknown,
{ id: number; values: UpdateProjectProps }
> => useMutation(
  ({ id, values }: { id: number; values: UpdateProjectProps }) => new ProjectRepository().updateProjectAnnualEnergy(id, values),
  {
    onSuccess,
  },
);

export const useProjectReportFinalizedUpdate = (
  onSuccess,
): UseMutationResult<
IdResponse,
unknown,
{ id: number }
> => useMutation(
  ({ id }: { id: number }) => new ProjectRepository().updateProjectReportFinalized(id),
  {
    onSuccess,
  },
);

export const useProjectInterventionPerformedUpdate = (onSuccess): UseMutationResult<
IdResponse,
unknown,
{ id: number }
> => useMutation(
  ({ id }: { id: number }) => new ProjectRepository().updateProjectInterventionPerformed(id),
  { onSuccess },
);

export const useProjectRemove = (
  onSuccess,
): UseMutationResult<IdResponse, unknown, number> => useMutation((id: number) => new ProjectRepository().removeProject(id), {
  onSuccess,
});

export const useProjectsStatsPhase = ({ technician, admin }: StatsPhaseProjectProps): UseQueryResult<StatsPhaseProjectResponse[], StatsPhaseProjectProps> => useQuery(
  [PROJECTS_PHASE_STATS, technician, admin],
  () => new ProjectRepository().statsPhaseProject({ technician, admin }),
);
