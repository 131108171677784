import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CreateProjectProps, EPhase } from '../../../../modules/project/domain/project.entity';
import { buildingDefaultValue } from './buildingDefaultValue';

export const useFormProjectHook = () => useForm<CreateProjectProps>({
  defaultValues: {
    name: undefined,
    phase: EPhase.TO_PROGRAM,
    interventionDate: null,
    interventionType: undefined,
    city: undefined,
    zipcode: undefined,
    streetNumber: undefined,
    streetName: undefined,
    boilerRoomKeys: undefined,
    buildings: [buildingDefaultValue],
  },
});

export const useToggleModal = (closeModal, open) => {
  useEffect(() => {
    if (!open) {
      closeModal(false);
    }
  }, [closeModal, open]);
};
