/* eslint-disable no-unused-vars */
export enum ESort {
  ASC = 'ASC',
  DESC = 'DESC'
}
export type PaginateProps = {
  page: number;
  limit: number;
}

export type SearchProps = {
  search: string;
}

export type SortProps<EntityProps> = {
  order: keyof EntityProps;
  sort: ESort;
}

export interface OptionsQuery {
  keepPreviousData?: boolean;
  enabled?: boolean;
}
