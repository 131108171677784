import {
  Table, TableBody, TableContainer, TableRow,
} from '@mui/material';
import { isArray } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../../hooks/useTranslation';
import { ERoutePath } from '../../../interface-adaptaters/interfaces/router/route.interface';
import { ENameSpaceTranslation } from '../../../libs/i18next';
import { useAppSelector } from '../../../libs/store';
import { SearchCompaniesResponse } from '../../../modules/company/responses/SearchCompanies.response.dto';
import { SearchContactsResponse } from '../../../modules/contact/responses/SearchContacts.response.dto';
import { SearchProjectsResponse } from '../../../modules/project/responses/Search-project.response';
import { ListItem } from '../List/ListItem';
import { ListContainerStyle } from './style';

export function SearchList({
  contactsSearch,
  companiesSearch,
  projectsSearch,
  setClean,
}: {
  contactsSearch?: SearchContactsResponse[],
  companiesSearch?: SearchCompaniesResponse[],
  projectsSearch?: SearchProjectsResponse[],
  setClean: Dispatch<SetStateAction<boolean>>,
}) {
  const { show } = useAppSelector(({ search }) => search);
  const { t } = useTranslation();

  if (show && isArray(contactsSearch) && contactsSearch.length > 0) {
    return (
      <TableContainer
        style={ListContainerStyle}
      >
        <Table>
          <TableBody>
            {contactsSearch.map((contact) => (
              <Link
                to={ERoutePath.CONTACT.replace(':id', contact.id.toString())}
                onClick={() => setClean(true)}
                style={{
                  display: 'contents',
                }}
              >
                <TableRow hover tabIndex={-1} key={contact.id}>
                  <ListItem
                    value={contact.firstname ?? '-'}
                    minWidth={0}
                  />
                  <ListItem
                    value={contact.lastname ?? '-'}
                    minWidth={0}
                  />
                  <ListItem
                    value={contact.email ?? '-'}
                    minWidth={0}
                  />
                </TableRow>
              </Link>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    );
  } if (show && isArray(projectsSearch) && projectsSearch.length > 0) {
    return (
      <TableContainer
        style={ListContainerStyle}
      >
        <Table>
          <TableBody>
            {projectsSearch.map((project) => (
              <Link
                to={ERoutePath.PROJECT.replace(':id', project.id.toString())}
                onClick={() => setClean(true)}
                style={{
                  display: 'contents',
                }}
              >
                <TableRow hover tabIndex={-1} key={project.id}>
                  <ListItem
                    value={project.name ?? '-'}
                    minWidth={0}
                  />
                  <ListItem
                    value={t(project.phase, [ENameSpaceTranslation.PROJECT])}
                    minWidth={0}
                  />
                  <ListItem
                    value={project.technician?.contact.fullname ?? '-'}
                    minWidth={0}
                  />
                </TableRow>
              </Link>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    );
  } if (show && isArray(companiesSearch)) {
    return (
      <TableContainer
        style={ListContainerStyle}
      >
        <Table>
          <TableBody>
            {companiesSearch.map((company) => (
              <Link
                to={ERoutePath.COMPANY.replace(':id', company.id.toString())}
                onClick={() => setClean(true)}
                style={{
                  display: 'contents',
                }}
              >
                <TableRow hover tabIndex={-1} key={company.id}>
                  <ListItem
                    value={company.socialReason ?? '-'}
                    minWidth={0}
                  />
                  <ListItem
                    value={t(company.type, [ENameSpaceTranslation.COMPANY])}
                    minWidth={0}
                  />
                </TableRow>
              </Link>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    );
  }
  return <p />;
}

SearchList.defaultProps = {
  contactsSearch: [],
  companiesSearch: [],
  projectsSearch: [],
};
