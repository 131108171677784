import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as React from 'react';
import { SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../libs/store';
import { ESearchSelect, searchSlice } from '../../../modules/search/redux/slice';

export function SearchSelect() {
  const searchSelect = useAppSelector(({ search }) => search.select);
  const [select, setSelect] = React.useState(searchSelect);
  const { setSearchSelect } = searchSlice.actions;
  const dispatch = useDispatch();

  const handleChange = (event: SelectChangeEvent<ESearchSelect>) => {
    setSelect(event.target.value as SetStateAction<ESearchSelect>);
    dispatch(setSearchSelect(event.target.value));
  };

  React.useEffect(() => {
    setSelect(searchSelect);
  }, [searchSelect]);

  return (
    <Box sx={{ minWidth: 120, md: 'flex', alignSelf: 'center' }}>
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={select}
          size="small"
          onChange={handleChange}
          sx={{ borderColor: 'black', fontSize: '13px' }}
        >
          <MenuItem value="project">Projets</MenuItem>
          <MenuItem value="contact">Contacts</MenuItem>
          <MenuItem value="company">Entreprises</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
