import { BuildingProps, EPropertyType } from '../../../../modules/project/domain/project.entity';

export const buildingDefaultValue: BuildingProps = {
  name: '',
  propertyType: EPropertyType.RESIDENTIAL,
  habitantType: undefined,
  buildingType: undefined,
  numberLot: undefined,
  numberRoom: undefined,
  numberBed: undefined,
  shab: undefined,
  constructionYear: undefined,
  heatingLevel: undefined,
  closeMonthStateExpenses: undefined,
};
