import { Button } from '@mui/material';
import styled from 'styled-components';

export const BarElement = styled.div`
  border-radius: 5px;
  box-shadow: 0px 1px 4px #e3e3e3;
  padding: 20px 10px;
  max-width: 300px;
  margin-right: 10px;
  overflow: auto;
  @media (max-width: 900px) {
    max-width: 600px;
    width: 100%;
    margin-right: unset;
    margin: 0 auto;
  }
`;
export const BarContent = styled.div`
  @media (max-width: 900px) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  width: 250px;
  max-width: 250px;
  min-width: 250px;
`;

export const MainElement = styled.div`
  @media (max-width: 900px) {
    display: none;
  }
  flex-grow: 1;
  width: 70%;
  border-radius: 5px;
  box-shadow: 0px 1px 4px #e3e3e3;
  overflow: auto;
`;

export const BuildingCard = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const EditDiv = styled.div`
  display: flex;
`;

export const ContactField = styled.p`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 0.8rem;
  margin-right: 5px;

  svg {
    margin-right: 10px;
  }
`;

export const ProjectName = styled.h4`
  color: #fe8403;
  text-align: center;
  width: 225px; 
`;

export const VerticalContainer = styled.div`
display: flex;
flex-direction: column;
margin-top: 50px;
`;

export const CollapseButton = styled(Button)`
`;

export const ElementBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  &::after {
    content: "";
    bottom: 0;
    left: 0;
    margin-top: 15px;
    border-bottom: 1px dashed #fe8403;
  }
`;

export const ElementHeader = styled.div`
  /* padding: 5px; */
  /* margin: 5px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

export const ElementTitle = styled.h6`
  color: #fe8403;
`;

export const ElementSubTitle = styled.p`
  color: #fe8403;
  font-size: 14px;
  text-align: center;
`;

export const ViewDiv = styled.div`
  display: flex;
  align-items: center;
  word-break: break-word;
`;

export const FieldName = styled.p`
  font-weight: 400;
  width: 120px;
  min-width: 120px;
  font-size: 0.8rem;
  margin-right: 8px;
`;
export const FieldValue = styled.p`
  font-weight: 600;
  font-size: 0.8rem;
`;

export const ListStyled = styled.li`
  list-style-type: disc;
  margin: 0 20px;
`;
