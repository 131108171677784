/* eslint-disable react/require-default-props */
import { ListItem } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { ERoutePath } from '../../../../interface-adaptaters/interfaces/router/route.interface';

interface Props {
  label: string,
  to: ERoutePath
}
export default function NavBarLinks({ label, to }: Props) {
  return (
    <ListItem
      color="info"
      button
      component={Link}
      to={to}
      selected={to === useLocation().pathname}
      sx={{
        color: 'black',
        display: 'block',
        boxShadow: 0,
        fontSize: '14px',
        fontWeight: '500',
        borderRadius: '5px',
        padding: '8px',
      }}
    >
      {label}
    </ListItem>
  );
}
