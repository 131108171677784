import { Box, styled as style } from '@mui/material';

export const BoxStyled = style(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #000',
  borderRadius: '5px',
  boxShadow: theme.shadows[24],
  overflowY: 'scroll',
  padding: theme.spacing(4),
  zIndex: 500,
  maxHeight: '75%',
  width: '90%',
  maxWidth: '1300px',
}));
