/* eslint-disable no-unused-vars */
import { ComponentType, LazyExoticComponent } from 'react';
import { ETeam } from '../../../modules/user/domain/user.entity';

export enum ERoutePath {
  HOME = '/',
  PROJECTS = '/projects',
  CONTACTS = '/contacts',
  PROJECT = '/project/:id',
  CONTACT = '/contact/:id',
  COMPANY = '/company/:id',
  METADATAS = '/metadatas',
  PROFIL = '/profil',
  RECOMMENDATIONS = '/recommmendations',
  COMPANIES = '/companies',
  USERS = '/users',
  USER = '/user/:id',
  TOKENS = '/tokens',
  CONTRACTS = '/contracts',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/password/forgot',
  CHANGE_PASSWORD = '/password/change',
  CREATE_PASSWORD = '/password/create',
}

export interface IRoute {
  path: ERoutePath;
  component: LazyExoticComponent<ComponentType>;
  permissions?: ETeam[];
  walterre?: boolean;
}
