import { CSSProperties } from 'react';
import { Colors } from '../../utils/colors';

export const ListContainerStyle: CSSProperties = {
  position: 'absolute',
  top: '68px',
  left: '0',
  maxHeight: '200px',
  width: '100%',
  opacity: 1,
  backgroundColor: Colors.white,
  boxShadow: '0px 4px 8px 0px #B3B3B3',
  zIndex: 50,
};
