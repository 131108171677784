import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useOutsideAlerter } from '../../../../hooks/handleOutsideClick';
import { useAppDispatch, useAppSelector } from '../../../../libs/store';
import { useCompaniesListSearch } from '../../../../modules/company/queries/useCompanies';
import { useContactsListSearch } from '../../../../modules/contact/queries/useContacts';
import { useProjectsListSearch } from '../../../../modules/project/queries/useProjects';
import { ESearchSelect, searchSlice } from '../../../../modules/search/redux/slice';
import { SearchList } from '../../SearchList';
import { Search, SearchIconWrapper, StyledInputBase } from './style';

export function SearchBar() {
  const [searchInput, setSearchInput] = useState<string>('');
  const [tmpValue, setTmpValue] = useState<string>('');
  const dispatch = useAppDispatch();
  const { show, select } = useAppSelector(({ search }) => search);
  const [clean, setClean] = useState(false);
  const { hiddenSearchList, cleanList, setShow } = searchSlice.actions;

  const searchBarRef = React.useRef(null);
  useOutsideAlerter(searchBarRef, setClean);

  useEffect(() => {
    if (clean) {
      dispatch(hiddenSearchList());
      dispatch(cleanList());
      setClean(false);
    }
  }, [clean, cleanList, dispatch, hiddenSearchList]);
  const contactsSearch = useContactsListSearch({
    search: searchInput,
    enabled: Boolean(searchInput && select === ESearchSelect.CONTACT),
  });
  const companiesSearch = useCompaniesListSearch({
    search: searchInput,
    enabled: Boolean(searchInput && select === ESearchSelect.COMPANY),
  });
  const projectsSearch = useProjectsListSearch({
    search: searchInput,
    enabled: Boolean(searchInput && select === ESearchSelect.PROJECT),
  });

  useEffect(() => {
    if (contactsSearch.data || companiesSearch.data || projectsSearch.data) {
      dispatch(setShow(true));
    }
  }, [companiesSearch.data, contactsSearch.data, dispatch, projectsSearch.data, setShow]);

  useEffect(() => {
    if (!show) {
      setSearchInput('');
      setTmpValue('');
    }
  }, [show]);

  // Debounce onChange value for request performance
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFilter = useCallback(_.debounce(
    (value) => setSearchInput(value),
    500,

    { maxWait: 1000 },
  ), []);
  const handleChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setTmpValue(e.target.value as string);
    debouncedFilter(e.target.value);
  };

  return (
    <div
      ref={searchBarRef}
    >
      <Search
        sx={{
          color: '#bbbbbb',
          border: 1,
          marginLeft: '0px',
        }}
      >
        <div>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Recherche…"
            inputProps={{ 'aria-label': 'search' }}
            value={tmpValue}
            onChange={handleChange}
            sx={{
              color: 'rgb(133, 133, 133)',
              marginLeft: '0px',
              fontSize: '13px',
            }}
          />
        </div>
      </Search>
      <SearchList
        setClean={setClean}
        contactsSearch={contactsSearch.data}
        companiesSearch={companiesSearch.data}
        projectsSearch={projectsSearch.data}
      />
    </div>
  );
}
