import { useTranslation as useTranslationINext } from 'react-i18next';
import { ENameSpaceTranslation } from '../libs/i18next';

export const useTranslation = () => {
  const translate = useTranslationINext();
  const t = (value?: string | null, ns?: ENameSpaceTranslation[]) => {
    if (typeof value === 'boolean') {
      if (value) return 'Vrai';
      return 'Faux';
    }
    if (value) {
      return translate.t(value, { ns });
    }
    return '-';
  };
  return {
    t,
  };
};
