/* eslint-disable react/require-default-props */
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import GenericButton, { GenericButtonProps } from '../GenericButton';

export default function IconRemoveButtonWithHoverEffect({ ...props }: GenericButtonProps) {
  return (
    <GenericButton size="small" icon={<RemoveCircleOutlineIcon />} {...props} />
  );
}

IconRemoveButtonWithHoverEffect.defaultProps = {
  tooltipPlacement: 'bottom',
};
