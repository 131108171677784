/* eslint-disable no-unused-vars */
import { GenericChoices } from '../../../libs/ddd/interfaces/base.interface';
import { PaginateProps, SearchProps, SortProps } from '../../../libs/interfaces';
import { ContactProps } from '../../contact/domain/contact.entity';
import { ProjectPropsInCompany } from '../../project/domain/project.entity';

export enum EType {
  ADMINISTRATOR = 'administrator',
  EXPLOITING = 'exploiting',
  PROVIDER = 'provider',
  DESIGNOFFICE = 'designOffice',
  OWNER = 'owner',
  OTHER = 'other',
}
export const ListType: GenericChoices<EType>[] = [
  {
    value: EType.ADMINISTRATOR,
    label: 'Gestionnaire',
  }, {
    value: EType.EXPLOITING,
    label: 'Exploitant',
  }, {
    value: EType.PROVIDER,
    label: 'Fournisseur d\'énergie',
  }, {
    value: EType.DESIGNOFFICE,
    label: 'Bureau d\'étude',
  }, {
    value: EType.OWNER,
    label: 'Maître d\'ouvrage',
  }, {
    value: EType.OTHER,
    label: 'Autre',
  },
];

export interface CompanyProps {
  id: number;
  socialReason: string;
  email: string | undefined;
  city: string | undefined;
  zipcode: string | undefined;
  streetNumber: string | undefined;
  streetName: string | undefined;
  phone: string | undefined;
  type: string;
  website: string | undefined;
  contacts: ContactProps[] | undefined;
  projects: ProjectPropsInCompany[] | undefined;
  comment: string | undefined;
}

export type CreateCompanyProps = Omit<CompanyProps, 'id'> & { address: string | undefined};
export type UpdateCompanyProps = Omit<CompanyProps, 'contactsId'>;
export type CompanyInEnergyContractProps = Omit<CompanyProps, 'contacts' | 'projects'>;
export type CompanyInMaintenanceContractProps = Omit<CompanyProps, 'contacts' | 'projects'>;
export type CompanyInIndividualCostHeatingContractProps = Omit<CompanyProps, 'contacts' | 'projects'>;
export type CompanyWithoutCompanyInContactsProps =
CompanyProps & { contacts: Omit<ContactProps, 'company' | 'projects'>[] | undefined };
export type SearchCompaniesProps = Pick<CompanyProps, 'socialReason' | 'type' | 'id' >;
export type PaginateCompanyProps = PaginateProps & Partial<SortProps<CompanyProps>> & Partial<SearchProps> & {
  types?: EType[];
}
