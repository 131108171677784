import { PaginateResponse } from '../../../libs/ddd/interfaces/base.interface';
import { EMethod, IQuery, UseAxios } from '../../../libs/useAxios';
import {
  CompanyProps, CreateCompanyProps,
  PaginateCompanyProps, SearchCompaniesProps, UpdateCompanyProps,
} from '../domain/company.entity';
import { CompanyResponse } from '../responses/Company.response.dto';
import { CompanyRepositoryPort } from './company.repository.port';

export class CompanyRepository implements CompanyRepositoryPort {
  async removeCompany(id: number): Promise<{ id: number; }> {
    const result = await new UseAxios<{ id: number }, { id: number }>({
      method: EMethod.DELETE,
      url: '/company',
      params: id.toString(),
    }).exec();
    return result;
  }

  async createCompany(company:CreateCompanyProps): Promise<{ id: number; }> {
    const result = await new UseAxios<CreateCompanyProps, Pick<CompanyResponse, 'id'>>({
      method: EMethod.POST,
      url: '/company',
      data: company,
    }).exec();
    return result;
  }

  async paginateCompanies({
    page, limit, order, sort, search, types,
  }: PaginateCompanyProps): Promise<PaginateResponse<CompanyProps[]>> {
    const query: IQuery[] = [
      { page: page.toString() },
      { limit: limit.toString() },
      { order: order ?? 'createdAt' },
      { sort: sort ?? 'DESC' },
      search ? { search } : {},
    ];
    if (types) {
      query.push({
        type: types.join(','),
      });
    }
    const result = await new UseAxios<{ count: number }, PaginateResponse<CompanyProps[]>>({
      method: EMethod.GET,
      url: '/companies',
      query,
    }).exec();
    return result;
  }

  async searchCompanies(search: string, enabled: boolean): Promise<SearchCompaniesProps[]> {
    if (!enabled) return [];
    const query: IQuery[] = [
      { page: '1' },
      { limit: '20' },
      { order: 'socialReason' },
      { sort: 'ASC' },
      search ? { search } : {},
    ];
    const result = await new UseAxios<{ count: number }, PaginateResponse<SearchCompaniesProps[]>>({
      method: EMethod.GET,
      url: '/companies',
      query,
    }).exec();
    return result.data;
  }

  async countCompanies(): Promise<{ count: number }> {
    const result = await new UseAxios<void, { count: number }>({
      method: EMethod.GET,
      url: '/companies/count',
    }).exec();
    return result;
  }

  async getOneCompany(id: number): Promise <CompanyProps> {
    const result = await new UseAxios<{ id: number }, CompanyProps>({
      method: EMethod.GET,
      url: '/company',
      params: id.toString(),
    }).exec();
    return result;
  }

  async updateCompany(id: number, company: UpdateCompanyProps): Promise <{ id: number }> {
    const result = await new UseAxios<UpdateCompanyProps, { id:number }>({
      method: EMethod.PUT,
      url: `/company/${id}`,
      data: company,
    }).exec();
    return result;
  }
}
