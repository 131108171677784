import { IdResponse, PaginateResponse } from '../../../libs/ddd/interfaces/base.interface';
import {
  EMethod, IHeaders, IQuery, UseAxios,
} from '../../../libs/useAxios';
import {
  ChangePasswordProps,
  CreateUserProps,
  ETeam, ForgotPasswordProps, LoginProps, LoginResponseProps, PaginateUserProps, UpdateUserMeProps, UpdateUserProps,
} from '../domain/user.entity';
import { UserResponse } from '../responses/user.response';
import { UserRepositoryPort } from './user.repository.port';

export class UserRepository implements UserRepositoryPort {
  async updateMe(user: UpdateUserMeProps): Promise<IdResponse> {
    const result = await new UseAxios<UpdateUserMeProps, IdResponse>({
      method: EMethod.PUT,
      url: '/user/me',
      data: user,
    }).exec();
    return result;
  }

  async updateProfilPictureUser({ file, title }: { file: Blob, title?: string }): Promise<IdResponse> {
    const bodyFormData = new FormData();
    bodyFormData.append('media', file, title ?? 'noname');
    const result = await new UseAxios<any, { id: number }>({
      method: EMethod.PATCH,
      url: '/user/profil-picture',
      headers: { 'Content-Type': 'multipart/form-data' } as IHeaders,
      data: bodyFormData,
    }).exec();
    return result;
  }

  async createUser(user: CreateUserProps): Promise<IdResponse> {
    const result = await new UseAxios<CreateUserProps, IdResponse>({
      method: EMethod.POST,
      url: '/user',
      data: user,
    }).exec();
    return result;
  }

  async updateUser(user: UpdateUserProps): Promise<IdResponse> {
    const result = await new UseAxios<UpdateUserProps, IdResponse>({
      method: EMethod.PUT,
      url: `/user/${user.id}`,
      data: user,
    }).exec();
    return result;
  }

  async oneUser(id: number): Promise<UserResponse> {
    const result = await new UseAxios<{ id: number }, UserResponse>({
      method: EMethod.GET,
      url: '/user',
      params: id.toString(),
    }).exec();
    return result;
  }

  async removeUser(id: number): Promise<IdResponse> {
    const result = await new UseAxios<number, IdResponse>({
      method: EMethod.DELETE,
      url: `/user/${id}`,
    }).exec();
    return result;
  }

  async paginateUsers({
    page, limit, search, sort, order,
  }: PaginateUserProps): Promise<PaginateResponse<UserResponse[]>> {
    const result = await new UseAxios<unknown, PaginateResponse<UserResponse[]>>({
      method: EMethod.GET,
      url: '/users',
      query: [
        { page: page.toString() },
        { limit: limit.toString() },
        { order: order ?? 'createdAt' },
        { sort: sort ?? 'DESC' },
        search ? { search } : {},
      ],
    }).exec();
    return result;
  }

  async forgotPassword(forgotPassword: ForgotPasswordProps): Promise<IdResponse> {
    const result = await new UseAxios<ForgotPasswordProps, IdResponse>({
      method: EMethod.PATCH,
      url: '/user/forgot/password',
      data: forgotPassword,
    }).exec();
    return result;
  }

  async changePassword(changePassword: ChangePasswordProps): Promise<IdResponse> {
    const result = await new UseAxios<ChangePasswordProps, IdResponse>({
      method: EMethod.PATCH,
      url: '/user/password',
      data: changePassword,
    }).exec();
    return result;
  }

  async findMe(): Promise<UserResponse> {
    const result = await new UseAxios<never, UserResponse>({
      method: EMethod.GET,
      url: '/user/me',
    }).exec();
    return result;
  }

  async login(login: LoginProps): Promise<LoginResponseProps> {
    const result = await new UseAxios<LoginProps, LoginResponseProps>({
      method: EMethod.POST,
      url: '/auth/login',
      data: login,
    }).exec();
    return result;
  }

  async allUsers(team?: ETeam): Promise<UserResponse[]> {
    const query: IQuery[] = [];
    query.push({
      team: team ?? '',
    });

    const result = await new UseAxios<void, UserResponse[]>({
      method: EMethod.GET,
      url: '/users/all',
      query,
    }).exec();
    return result;
  }
}
