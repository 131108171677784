/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  combineReducers, configureStore,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { opportunityStudySlice } from '../modules/opportunity-study-rd/redux/opportunityStudyReducer';
import { paginationSlice } from '../modules/pagination/redux/slice';
import { searchSlice } from '../modules/search/redux/slice';

const reducers = combineReducers({
  [searchSlice.name]: searchSlice.reducer,
  [paginationSlice.name]: paginationSlice.reducer,
  [opportunityStudySlice.name]: opportunityStudySlice.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }) as any,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ReturnType<typeof store.dispatch>;
export type AppThunk = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<any>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
